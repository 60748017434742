<template>
<div style="padding:15px;position:relative;
    min-height: 570px;
    background: #fff;">
  <div style="margin-bottom:80px;">
    <label class="label">Letter to hotel:</label>
    <p>Title: {{formatStr(order_form.content[landing.current_lang].subject)}}</p>
      <br><br>
    <p  v-html="formatStr(order_form.content[landing.current_lang].message_header)"></p>
  </div>

  <div v-if="order_form.content[landing.current_lang].send_guest">
    <label class="label">Letter to guest:</label>
    <p>Title: {{formatStr(order_form.content[landing.current_lang].subject_guest)}}</p>
      <br><br>
    <p  v-html="formatStr(order_form.content[landing.current_lang].message_header_guest)"></p>
  </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
    name:'EmailNotificationPreview',
    data(){
        return{

        }
    },
    computed:{
        ...mapState(['landing']),
      ...mapState('orders',['order_form'])
    },
    methods:{
        formatStr(str){
            let sub = str
            sub  = sub.replace(':date:',moment().format('DD.MM.yyyy'));
            sub = sub.replace(':time:',moment().format('HH:mm'));
            return sub
        }
    }
}
</script>

<style>

</style>
